import { request } from 'utils'

export enum CharityPartnerEndpoint {
  CharityPartner = '/charity_partner',
  GetById = '/charity_partner/id',
}

export interface CharityPartner {
  contractStart: Date
  name: string
  displayName: string
  number?: string
  isActive: boolean
  logo: string
  shortDescription: string
  longDescription: string
  notifyDescription?: string
  location: string
  contact: Contact
  _id?: string
  slug?: string
  expiryDate?: Date
  category: string
  __v?: number
  isPublicFoundation: boolean
}

export interface Contact {
  name: string
  phone: string
  email: string
  mailingAddress: string
}

export type GetCharityPartnersLimit = 10 | 25 | 50

export enum GetCharityPartnersSortMethod {
  DateNew = 'dateNew',
  DateOld = 'dateOld',
  NameAZ = 'nameAZ',
  NameZA = 'nameZA',
}

export interface GetCharityPartnersParams {
  page: number
  limit: GetCharityPartnersLimit
  q?: string
  sort?: GetCharityPartnersSortMethod
}

export interface GetCharityPartnersResponse {
  totalPages: string
  items: CharityPartner[]
}

export const createCharityPartner = (data: CharityPartner) => {
  return request({
    url: CharityPartnerEndpoint.CharityPartner,
    method: 'POST',
    data,
  })
}

export const getCharityPartners = (
  params: GetCharityPartnersParams,
): Promise<GetCharityPartnersResponse> => {
  return request({
    url: CharityPartnerEndpoint.CharityPartner,
    method: 'GET',
    params,
  }).then((res) => res.data.payload)
}

export const getCharityPartner = (id: string): Promise<CharityPartner> => {
  return request({
    url: `${CharityPartnerEndpoint.GetById}/${id}`,
    method: 'GET',
  }).then((res) => res.data.payload)
}

export const updateCharityPartner = (
  data: CharityPartner,
): Promise<CharityPartner> => {
  return request({
    url: `${CharityPartnerEndpoint.CharityPartner}/admin/${data._id}`,
    method: 'PATCH',
    data: { ...data, slug: undefined },
  }).then((res) => res.data.payload)
}
